import {cloneDeep, orderBy} from 'lodash';
import { DropdownModel } from '../geral/DropdownModel';
import moment from 'moment'
export class AprovarReprovarOrcamentoModel {
  constructor({
    id,
    codigo,
    comparativoPercentualReajusteTabelaPreco,
    condicaoPagamento = null,
    dataEmissao,
    dataEnvio,
    descricaoFormaSolicitacaoOrcamento,
    flagCondicaoPagamentoPorContrato,
    formaSolicitacaoOrcamento,
    localExecucao = null,
    numeroParcela,
    numeroPedidoCompra,
    observacao,
    orcamentoItem = [],
    orcamentoMensagem = [],
    orcamentoOrigemId,
    ordemServico = null,
    participanteCliente = null,
    participanteClienteContato,
    participanteFornecedor,
    prazoGarantia,
    responsavelUsuario = null,
    status,
    tipoDesconto,
    validadeProposta,
    valorTotalDesconto,
    valorTotalFrete,
    valorTotalItens,
    valorTotalOutrasDespesas,
    vencido,
    aprovarReprovar,
    pedidoCompra,
    comentario
  }) {
    this.id = id;
    this.codigo = codigo;
    this.comparativoPercentualReajusteTabelaPreco = comparativoPercentualReajusteTabelaPreco;
    this.condicaoPagamento = condicaoPagamento ? new DropdownModel(condicaoPagamento) : null;
    this.dataEmissao = dataEmissao ? moment(dataEmissao).format('DD/MM/YYYY') : null;
    this.dataEnvio = dataEnvio;
    this.descricaoFormaSolicitacaoOrcamento = descricaoFormaSolicitacaoOrcamento;
    this.flagCondicaoPagamentoPorContrato = flagCondicaoPagamentoPorContrato;
    this.formaSolicitacaoOrcamento = formaSolicitacaoOrcamento;
    this.localExecucao = localExecucao ? new DropdownModel(localExecucao) : null;
    this.numeroParcela = numeroParcela;
    this.numeroPedidoCompra = numeroPedidoCompra;
    this.observacao = observacao;
    this.aprovarReprovar= aprovarReprovar == 'aprovar' ? true : false;
    this.orcamentoItem = orcamentoItem?.map((item) => {return {
      ...item, checked: this.aprovarReprovar,
      orcamentoItemDetalhe: item.orcamentoItemDetalhe?.map((detalhe) => {return {...detalhe, checked:this.aprovarReprovar}})
    }});
    if(this.orcamentoItem.length > 0){
      this.orcamentoItem = orderBy(this.orcamentoItem, ['numeroItem'], ['asc'])
    }
    this.orcamentoMensagem = orcamentoMensagem;
    this.orcamentoOrigemId = orcamentoOrigemId;
    this.ordemServico = ordemServico ? new DropdownModel(ordemServico, 'codigo') : null;
    this.participanteCliente = participanteCliente ? new DropdownModel(participanteCliente, 'apelido') : null;
    this.participanteClienteContato = participanteClienteContato;
    this.participanteFornecedor = participanteFornecedor;
    this.prazoGarantia = prazoGarantia;
    this.responsavelUsuario = responsavelUsuario ? new DropdownModel(responsavelUsuario) : null;
    this.status = status;
    this.tipoDesconto = tipoDesconto;
    this.validadeProposta = validadeProposta;
    this.valorTotalDesconto = valorTotalDesconto;
    this.valorTotalFrete = valorTotalFrete;
    this.valorTotalItens = valorTotalItens;
    this.valorTotalOutrasDespesas = valorTotalOutrasDespesas;
    this.vencido = vencido;
    this.pedidoCompra = pedidoCompra;
    this.comentario = comentario;
  }
  get request() {
    let retorno = cloneDeep(this);
    retorno.orcamentosItens = retorno.orcamentoItem.map(item => {
      let orcamentosItensDetalhes = []
      if(item.checked){
        item.orcamentoItemDetalhe.forEach(detalhe => {
          if(detalhe.checked){
            orcamentosItensDetalhes.push({id:detalhe.id})
          }          
        });
        return {id: item.id, orcamentosItensDetalhes}
      }
    }).filter(item => item);

    return retorno;
  }
}
