<template>
  <div>
    <div class="titulo-pagina mb-2">
      {{ $t('modulos.orcamentos.formulario.aprovar_orcamento') }}
    </div>
    <div class="mb-2">
      <v-chip
        v-if="!!form?.codigo"
        class="mr-1"
        small
      >
        {{ $t('modulos.orcamentos.badges.codigo') }}: {{ form?.codigo }}
      </v-chip>
      <v-chip
        v-if="!!form.participanteCliente?.text"
        class="mr-1"
        small
      >
        {{ $t('modulos.orcamentos.badges.cliente') }}:
        {{ form.participanteCliente?.text }}
      </v-chip>
      <v-chip
        v-if="!!form?.dataEmissao"
        class="mr-1"
        small
      >
        {{ $t('modulos.orcamentos.badges.data_emissao') }}:
        {{ form?.dataEmissao }}
      </v-chip>
    </div>
    <p>{{ $t('modulos.orcamentos.formulario.mensagem_para_usuario') }}</p>
    <v-data-table
      :show-select="false"
      class="mb-10"
      :headers="tabela.colunas"
      :items="form.orcamentoItem"
      item-key="id"
      show-expand
      dense
      hide-default-footer
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.seletor="{ item }">
        <div style="margin-left: 8px">
          <v-simple-checkbox
            v-model="item.checked"
            @click="checkTodosItens(item.id, true)"
          />
        </div>
      </template>
      <template v-slot:item.numero="{ item }">
        <div class="d-flex">
          <!-- <div v-if="false">
            <dropdown-padrao
              text
              color="secondary"
              style="width: 100% !important"
            >
              <template #botao>
                <v-icon> $dotsVertical </v-icon>
              </template>

              <v-list-item-group style="overflow-y: auto">
                <v-list-item
                  class="d-flex min-height-drop-item"
                  @click="duplicar(item)"
                >
                  {{ $t('geral.botoes.duplicar') }}
                </v-list-item>
                <v-divider />
                <v-list-item
                  class="d-flex min-height-drop-item"
                  @click="excluirPrincipal(item.id)"
                >
                  <v-icon>$mdiTrashCanOutline</v-icon>
                  {{ $t('geral.botoes.excluir') }}
                </v-list-item>
              </v-list-item-group>
            </dropdown-padrao>
          </div> -->
          <div>
            {{ item.numeroItem }}
          </div>
        </div>
      </template>
      <template v-slot:item.instrumento="{ item }">
        <div class="d-flex">
          <div
            style="align-self: center"
            class="mr-2"
          />
          <div class="d-flex">
            <accordion-tabela v-if="item.orcamentoItemInstrumento?.length > 1">
              <template v-slot:header="{ on }">
                <div
                  class="d-flex"
                  style="width: 125px"
                  v-on="item.orcamentoItemInstrumento[0]?.nome ? on : null"
                >
                  <span>{{ item.orcamentoItemInstrumento?.length }} </span>
                  <span> {{ '-Instrumentos' }} </span>
                </div>
              </template>
              <div
                v-for="{ instrumento } in item.orcamentoItemInstrumento"
                :key="instrumento.id"
              >
                <v-chip
                  label
                  outlined
                  color="cinza200"
                >
                  <span>{{ instrumento.nome }}</span>
                </v-chip>
              </div>
            </accordion-tabela>

            <div v-if="item.orcamentoItemInstrumento?.length == 1">
              <div
                v-for="{ instrumento } in item.orcamentoItemInstrumento"
                :key="instrumento.id"
              >
                <v-chip
                  label
                  outlined
                  color="cinza200"
                >
                  <span>{{ instrumento.nome }}</span>
                </v-chip>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:item.descricao="{ item }">
        <v-tooltip
          v-if="item.descricao"
          top
          class="pl-0"
        >
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <div class="col-12 cursor-pointer texto-descricao">
                <div
                  class="truncar-descricao"
                  v-html="item.descricao"
                />
              </div>
            </div>
          </template>
          <span>{{ item.descricao }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.valorUnitario="{ item }">
        {{ item.valorUnitario | formataValorPrecisaoDois }}
      </template>

      <template v-slot:item.desconto="{ item }">
        {{ item.desconto | formataValorPrecisaoDois }}
      </template>
      <template v-slot:item.valorFrete="{ item }">
        {{ item.valorFrete | formataValorPrecisaoDois }}
      </template>
      <template v-slot:item.outrasDespesas="{ item }">
        {{ item.outrasDespesas | formataValorPrecisaoDois }}
      </template>
      <template v-slot:item.valorTotal="{ item }">
        {{ item.valorTotal | formataValorPrecisaoDois }}
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="th-simple-table"
                    scope="col"
                  />
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.orcamentos.formulario.codigo') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.orcamentos.formulario.descricao') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.orcamentos.formulario.prazo') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.orcamentos.formulario.qtd') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.orcamentos.formulario.valor_unitario') }}
                  </th>

                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.orcamentos.formulario.desconto') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.orcamentos.formulario.frete') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.orcamentos.formulario.despesas') }}
                  </th>
                  <th
                    class="th-simple-table"
                    scope="col"
                  >
                    {{ $t('modulos.orcamentos.formulario.valor_total') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="instrumento in item.orcamentoItemDetalhe"
                  :key="instrumento.numeroItem"
                >
                  <td>
                    <v-simple-checkbox
                      v-model="instrumento.checked"
                      style="margin-left: 8px"
                      @click="checkItemIndividual(item.id, instrumento)"
                    />
                  </td>
                  <td class="mr-2 col-2">
                    <div class="d-flex">
                      <dropdown-padrao
                        v-if="false"
                        :drop-w100="false"
                        text
                        color="secondary"
                      >
                        <template #botao>
                          <v-icon> $dotsVertical </v-icon>
                        </template>
                        <v-list-item-group style="overflow-y: auto">
                          <v-list-item
                            class="d-flex min-height-drop-item"
                            @click="excluirSecundario(item.id, instrumento.id)"
                          >
                            {{ $t('geral.botoes.remover') }}
                          </v-list-item>
                        </v-list-item-group>
                      </dropdown-padrao>
                      <div class="d-flex">
                        <span class="mt-1 mr-1">
                          <icone-padrao
                            v-if="instrumento?.itemId || instrumento?.item"
                            :icone="'$cogOutline'"
                            :tooltip="'Peça'"
                          />
                          <icone-padrao
                            v-if="instrumento?.servico?.tipoServico == 'Calibracao'"
                            :icone="'$wrenchClock'"
                            :tooltip="'Serviço de Calibração'"
                          />
                          <icone-padrao
                            v-if="instrumento?.servico?.tipoServico == 'Manutencao'"
                            :icone="'$wrenchCog'"
                            :tooltip="'Serviço de Manutenção'"
                          />
                          {{ instrumento.codigo }}
                        </span>
                        <span class="mt-2">
                          {{
                          instrumento?.servico?.codigo ||
                          instrumento?.item?.nome
                          }}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="col-3">
                    <v-tooltip
                      v-if="instrumento.descricao"
                      top
                      class="pl-0"
                    >
                      <template v-slot:activator="{ on }">
                        <div v-on="on">
                          <div class="col-12 cursor-pointer texto-descricao">
                            <div
                              class="truncar-descricao"
                              v-html="instrumento.descricao"
                            />
                          </div>
                        </div>
                      </template>
                      <span>{{ instrumento.descricao }}</span>
                    </v-tooltip>
                  </td>
                  <td class="col-1">
                    {{ instrumento.prazoEntrega }}
                  </td>
                  <td class="col-1">
                    {{ instrumento.quantidade }}
                  </td>
                  <td
                    class="col-1"
                    style="text-align-last: right"
                  >
                    {{ instrumento.valorUnitario | formataValorPrecisaoDois }}
                  </td>

                  <td
                    class="col-1"
                    style="text-align-last: right"
                  >
                    {{ instrumento.desconto | formataValorPrecisaoDois }}
                  </td>
                  <td
                    class="col-1"
                    style="text-align-last: right"
                  >
                    {{ instrumento.valorFrete | formataValorPrecisaoDois }}
                  </td>
                  <td
                    class="col-1"
                    style="text-align-last: right"
                  >
                    {{ instrumento.outrasDespesas | formataValorPrecisaoDois }}
                  </td>
                  <td
                    class="col-1"
                    style="text-align-last: right"
                  >
                    {{ instrumento.valorTotal | formataValorPrecisaoDois }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </td>
      </template>
    </v-data-table>

    <div class="row mr-1 mb-2">
      <div class="col-6">
        <input-text
          v-model="form.pedidoCompra"
          class="mb-2 ml-2"
          :label="$t('modulos.orcamentos.formulario.pedido_compra')"
        />
      </div>
    </div>

    <div
      class="d-flex mb-2"
      style="flex-direction: row"
    >
      <div class="span-total item-valor">
        {{ $t('modulos.orcamentos.formulario.valor_total') }}
        R$ {{ form.valorTotalItens | formataValorPrecisaoDois }}
      </div>
      <div class="span-total item-valor">
        {{ $t('modulos.orcamentos.formulario.valor_aprovado') }}
        R$ {{ valorAprovado | formataValorPrecisaoDois }}
      </div>
    </div>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end mt-4">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="voltar"
      >
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
      <botao-padrao @click="aprovar">
        {{ $t('geral.botoes.finalizar') }}
      </botao-padrao>
    </div>

    <reprovar-modal
      ref="modal-reprovar"
      @motivo-reprovacao="reprovarOrcamento"
    />
  </div>
</template>
<script>
import OrcamentosService from '@common/services/cadastros/OrcamentosService';
// import helpers from '@common/utils/helpers';
import ReprovarModal from '@views/modulos/cadastros/orcamentos/components/modais/ReprovarModal';

import { AprovarReprovarOrcamentoModel } from '@/common/models/cadastros/AprovarReprovarOrcamentoModel';
export default {
  components: {
    ReprovarModal,
  },
  props: ['id'],
  data() {
    return {
      form: new AprovarReprovarOrcamentoModel({}),
      pedidoCompra: null,
      valorAprovado: 0,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'seletor',
            text: '',
            sortable: false,
          },
          {
            value: 'numero',
            text: this.$t('modulos.orcamentos.formulario.numero'),
            sortable: false,
          },
          {
            value: 'instrumento',
            text: this.$t('modulos.orcamentos.formulario.instrumento'),
            sortable: false,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.orcamentos.formulario.descricao'),
            sortable: false,
          },
          {
            value: 'prazoEntrega',
            text: this.$t('modulos.orcamentos.formulario.prazo'),
            sortable: false,
          },
          {
            value: 'quantidade',
            text: this.$t('modulos.orcamentos.formulario.qtd'),
            sortable: false,
          },
          {
            value: 'valorUnitario',
            text: this.$t('modulos.orcamentos.formulario.valor_unitario'),
            sortable: false,
            align: 'right',
            width: '135px',
          },

          {
            value: 'desconto',
            text: this.$t('modulos.orcamentos.formulario.desconto'),
            sortable: false,
            align: 'right',
          },
          {
            value: 'valorFrete',
            text: this.$t('modulos.orcamentos.formulario.frete'),
            sortable: false,
            align: 'right',
          },
          {
            value: 'outrasDespesas',
            text: this.$t('modulos.orcamentos.formulario.despesas'),
            sortable: false,
            align: 'right',
          },
          {
            value: 'valorTotal',
            text: this.$t('modulos.orcamentos.formulario.valor_total'),
            sortable: false,
            align: 'right',
            width: '135px',
          },
        ],
      },
    };
  },
  computed: {
    aprovarOuReprovar() {
      return this.$route.query?.aprovarOuReprovar;
    },
  },

  async mounted() {
    await this.buscar();
  },
  methods: {
    buscar: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await OrcamentosService.buscar(this.id)
        .then((res) => {
          this.form = new AprovarReprovarOrcamentoModel({
            ...res.data,
            aprovarReprovar: this.aprovarOuReprovar,
          });
          this.calcularValorAprovado();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    voltar() {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'orcamentos' });
      });
    },
    abrirModalReprovar() {
      this.$refs['modal-reprovar'].abrirModal();
    },
    checkTodosItens(itemId, foiClick) {
      if (!foiClick) return;
      const item = this.form.orcamentoItem.find((i) => i.id === itemId);
      item.orcamentoItemDetalhe.forEach((i) => {
        i.checked = item.checked;
      });
      this.calcularValorAprovado();
    },
    checkItemIndividual(itemId) {
      const item = this.form.orcamentoItem.find((i) => i.id === itemId);
      const temAlgumItemSelecionado = item.orcamentoItemDetalhe.some(
        (i) => i.checked
      );
      item.checked = temAlgumItemSelecionado;
      this.calcularValorAprovado();
    },

    calcularValorAprovado() {
      let valorAprovado = 0;
      this.form.orcamentoItem.forEach((item) => {
        item.valorTotal = 0;
        item.orcamentoItemDetalhe.forEach((detalhe) => {
          if (detalhe.checked) {
            valorAprovado += detalhe.valorTotal;
            item.valorTotal += detalhe.valorTotal;
          }
        });
      });
      this.valorAprovado = valorAprovado;
    },
    aprovar() {
      const temAlgumItemSelecionado = this.form.orcamentoItem.some(
        (i) => i.checked
      );

      if (temAlgumItemSelecionado) {
        this.confirmar(
          this.$t(
            'modulos.orcamentos.aprovar_reprovar_orcamento.aprovar_titulo'
          ),
          this.$t(
            'modulos.orcamentos.aprovar_reprovar_orcamento.aprovar_mensagem'
          )
        ).then(() => {
          this.$store.dispatch('Layout/iniciarCarregamento');
          OrcamentosService.aprovarOrcamento(this.form.request)
            .then(() => {
              this.toastSucesso(
                this.$t(`modulos.orcamentos.orcamento_aprovado`)
              );
              this.$router.push({ name: 'orcamentos' });
            })
            .finally(() => {
              this.$store.dispatch('Layout/terminarCarregamento');
            });
        });
      } else {
        this.confirmar(
          this.$t(
            'modulos.orcamentos.aprovar_reprovar_orcamento.reprovar_titulo'
          ),
          this.$t(
            'modulos.orcamentos.aprovar_reprovar_orcamento.reprovar_mensagem'
          )
        ).then(() => {
          this.$refs['modal-reprovar'].abrirModal();
        });
      }
    },
    reprovarOrcamento(justificativa) {
      this.form.comentario = justificativa.comentario
      this.$store.dispatch('Layout/iniciarCarregamento');
      OrcamentosService.reprovarOrcamento(this.form.request)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.orcamentos.orcamento_reprovado`));
          this.$router.push({ name: 'orcamentos' });
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    // ESSAS FUNÇÔES ABAIXO VAO FICAR PARA A FASE V2 PQ NAO FAZ SENTIDO AINDA

    // duplicar(item) {
    //   this.form.orcamentoItem.push(item);
    // },
    // excluirPrincipal(id) {
    //   this.form.orcamentoItem = this.form.orcamentoItem.filter(
    //     (i) => i.id !== id
    //   );
    // },
    // excluirSecundario(id, subId) {
    //   const item = this.form.orcamentoItem.find((i) => i.id === id);
    //   item.orcamentoItemDetalhe = item.orcamentoItemDetalhe.filter(
    //     (i) => i.id !== subId
    //   );
    // },
  },
};
</script>
<style>
.th-simple-table {
  background-color: #d0d5dd;
}

.item-valor {
  flex: 1;
  margin: 5px;
  padding-left: 10px;
  font-size: 1.5em;
  padding: 12px;
}

.item-pedido {
  font-size: 15px;
  color: #98a2b3;
  border: 1px solid #98a2b3;
  border-radius: 7px;
  border-width: 1px;
}

.botao-reprovar {
  text-transform: capitalize;
  padding: 0 12px !important;
  min-width: 0px !important;
  border-radius: 8px !important;
  color: red !important;
  background-color: white !important;
  border: 1px solid red;
}
.truncar-descricao {
  max-width: 250px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
