<template>
  <modal-padrao
    ref="modal-reprovar"
    max-width="80%"
    titulo="Reprovar Orçamento"
    titulo-cancelar="Fechar"
    :ok-desabilitado="!valido"
    titulo-ok="Enviar"
    @ok="enviarMotivoReprovacao"
  >
    <v-form
      ref="form"
      class="row mt-2"
    >
      <input-textarea
        v-model="form.comentario"
        class="col-12"
        :label="$t('modulos.mensagem_orcamento.formulario.motivo_reprova')"
        :placeholder="
          $t('modulos.mensagem_orcamento.formulario.motivo_reprova')
        "
        :max="2000"
        obrigatorio
        trim
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import TipoComentarioOrcamentoService from '@common/services/cadastros/TipoComentarioOrcamentoService';
export default {
  props: {
    requisitosCliente: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      valido: null,
      form: {},
      loadingTipoComentario: false,
      colecaoTiposComentarios: [],
    };
  },
  watch: {
    form: {
      handler() {
        if (this.$refs.form) this.valido = this.$refs.form.validate();
      },
      deep: true,
    },
  },
  mounted() {
    this.buscarTipoComentariosObservacao()
  },
  methods: {
    buscarTipoComentariosObservacao() {

      this.loadingTipoComentario = true;
      TipoComentarioOrcamentoService.listar()
        .then((res) => {
          if (res?.data) {
            res?.data?.items.map((item) => {
              this.colecaoTiposComentarios.push({
                text: item.nome,
                value: item.id,
              });
            });
          } else this.colecaoTiposComentarios = [];
        })
        .finally(() => {
          this.loadingTipoComentario = false;
        });
    },
    abrirModal: function () {
      this.$refs['modal-reprovar'].abrirModal();
    },
    enviarMotivoReprovacao() {
      this.$emit('motivo-reprovacao', this.form);
      this.$refs['modal-reprovar'].fecharModal();
    },
  },
};
</script>
<style>
.accordion-padrao {
  color: #0d47a1;
}
</style>
